import { ColumnOrderState } from "@tanstack/react-table";
import { ChangeEvent, DragEvent } from "react";
import { Option, FormDataProps } from "./AddNewSourcesModal"; // Adjust the path if necessary
import { OnChangeValue } from "react-select";
import { environment } from "environments";
import { AxiosInstance } from "axios";
import { User } from "redux/features/auth/authSlice";

export const handleDragStart = (
  e: React.DragEvent<HTMLTableCellElement>,
  headerId: string
) => {
  e.dataTransfer.setData("text/plain", headerId);
  e.currentTarget.style.backgroundColor = "#dce0e5";
};

export const handleDrop = (
  e: React.DragEvent<HTMLTableCellElement>,
  targetHeaderId: string,
  columnOrder: ColumnOrderState,
  setColumnOrder: React.Dispatch<React.SetStateAction<ColumnOrderState>>
) => {
  e.preventDefault();
  const draggedHeaderId = e.dataTransfer.getData("text/plain");
  const newColumnOrder = [...columnOrder];
  const draggedIndex = newColumnOrder.indexOf(draggedHeaderId);
  const targetIndex = newColumnOrder.indexOf(targetHeaderId);

  newColumnOrder.splice(draggedIndex, 1);
  newColumnOrder.splice(targetIndex, 0, draggedHeaderId);
  setColumnOrder(newColumnOrder);
  e.currentTarget.style.border = "";
};

export const handleDragEnd = (e: React.DragEvent<HTMLTableCellElement>) => {
  e.currentTarget.style.backgroundColor = "";
};

export const handleDragOver = (e: React.DragEvent<HTMLTableCellElement>) => {
  e.preventDefault();
  e.currentTarget.style.border = "2px dashed #cbd5e0";
};

export const handleDragLeave = (e: React.DragEvent<HTMLTableCellElement>) => {
  e.currentTarget.style.border = "";
};

// ADD NEW PROPRIETARY SOURCES
export const loadMoreFiles = (
  setVisibleFilesCount: React.Dispatch<React.SetStateAction<number>>
) => {
  setVisibleFilesCount((prevCount) => prevCount + 3);
};

export const CollapseAll = (
  setOpenShowFilesModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setOpenShowFilesModal(true);
};

export const handleInputChange = (
  field: any,
  value: string | boolean,
  setFormData: React.Dispatch<React.SetStateAction<FormDataProps>>,
  connectedTeams: any[]
) => {
  setFormData((prev: any) => ({
    ...prev,
    [field]: { ...prev[field], value, invalid: false },
  }));

  if (field === "shared_with_Team") {
    if (value === true && connectedTeams?.length === 1) {
      const singleTeamId = connectedTeams[0].id;
      setFormData((prev: any) => ({
        ...prev,
        has_access: {
          ...prev["has_access"],
          value: [singleTeamId],
          invalid: false,
        },
      }));
    } else if (value === false) {
      setFormData((prev: any) => ({
        ...prev,
        has_access: { ...prev["has_access"], value: [], invalid: false },
      }));
    }
  }
};

export const handleFileChange = (
  e: ChangeEvent<HTMLInputElement>,
  handleFileValidation: (files: FileList | null) => void
) => {
  e.preventDefault();
  handleFileValidation(e.target.files);
};

export const handleDropFiles = (
  e: DragEvent,
  setDragActive: React.Dispatch<React.SetStateAction<boolean>>,
  handleFileValidation: (files: FileList | null) => void
) => {
  e.preventDefault();
  setDragActive(false);
  handleFileValidation(e.dataTransfer?.files);
};

export const handleDragFiles = (
  e: DragEvent,
  setDragActive: React.Dispatch<React.SetStateAction<boolean>>
) => {
  e.preventDefault();
  setDragActive(e.type === "dragenter" || e.type === "dragover");
};

export const handleFileValidation = (
  files: FileList | null,
  formData: FormDataProps,
  setFormData: React.Dispatch<React.SetStateAction<FormDataProps>>,
  MAX_FILE_COUNT: number
) => {
  if (!files) return;

  const newFiles = Array.from(files).filter(
    (file) => file.type === "application/pdf"
  );
  const currentFiles = formData.files.value;
  const filesToProcess = newFiles.slice(
    0,
    MAX_FILE_COUNT - currentFiles.length
  );
  let error = "";
  let validFiles: File[] = [];

  for (const file of filesToProcess) {
    if (
      currentFiles.some(
        (existingFile: { name: string }) => existingFile.name === file.name
      )
    ) {
      error = "File already exists";
      continue;
    } else {
      validFiles.push(file);
    }
  }

  if (newFiles.length > MAX_FILE_COUNT) {
    error =
      "Max 10 files allowed per upload! We have picked the first 10 files.";
  }

  setFormData((prev: any) => ({
    ...prev,
    files: {
      ...prev.files,
      value: [...prev.files.value, ...validFiles],
      invalid: !!error,
      error,
    },
  }));
};

export const handleViewFile = (file: File) => {
  return file;
};
export const handleRemoveFile = (
  fileName: string,
  formData: FormDataProps,
  setFormData: React.Dispatch<React.SetStateAction<FormDataProps>>
) => {
  setFormData((prev: any) => {
    const updatedFiles = prev.files.value.filter(
      (file: any) => file.name !== fileName
    );
    return {
      ...prev,
      files: {
        ...prev.files,
        value: updatedFiles,
        invalid: false,
        error: "",
      },
    };
  });
};

export const handleTeamSelection = (
  newValue: OnChangeValue<Option, true>,
  setFormData: React.Dispatch<React.SetStateAction<FormDataProps>>
) => {
  const teams_ids = newValue.map((team) => team?.id);
  setFormData((prevFormData: any) => ({
    ...prevFormData,
    has_access: {
      ...prevFormData.has_access,
      value: [...new Set([...prevFormData.has_access.value, ...teams_ids])],
    },
  }));
};

export const clearForm = (
  setUploadProgress: React.Dispatch<React.SetStateAction<number>>,
  setFormData: React.Dispatch<React.SetStateAction<FormDataProps>>
) => {
  setFormData({
    shared_with_Team: { value: false, invalid: false, error: "" },
    files: { value: [], invalid: false, error: "" },
    has_access: { value: [], invalid: false, error: "" },
  });
  setUploadProgress(0);
};

export const handleUploadFiles = async (
  axiosPrivate: AxiosInstance,
  formData: FormDataProps,
  setFormData: React.Dispatch<React.SetStateAction<FormDataProps>>,
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  setUploadProgress: React.Dispatch<React.SetStateAction<number>>,
  onClose: () => void,
  toast: (options: any) => void,
  colors: any,
  MAX_TOTAL_SIZE: number,
  navigate: (options: any) => void,
  user: User,
  saveUserActivity: (activity: any) => void
) => {
  setSubmitting(true);

  if (
    formData.files.value.length === 0 ||
    (formData.shared_with_Team.value && formData.has_access.value.length === 0)
  ) {
    setFormData((prev) => ({
      ...prev,
      files: {
        ...prev.files,
        invalid: formData.files.value.length === 0,
        error:
          formData.files.value.length === 0
            ? "Please upload at least one file"
            : "",
      },
      has_access: {
        ...prev.has_access,
        invalid: formData.has_access.value.length === 0,
        error:
          formData.has_access.value.length === 0
            ? "Please select at least one team or uncheck public option"
            : "",
      },
    }));
    setSubmitting(false);
    return;
  }

  const totalSize =
    formData.files.value.reduce((acc, file) => acc + file.size, 0) /
    (1024 * 1024); // size in MB

  if (totalSize > MAX_TOTAL_SIZE) {
    setFormData((prev) => ({
      ...prev,
      files: {
        ...prev.files,
        invalid: true,
        error: "Total file size exceeds 1GB. Please remove some files.",
      },
    }));
    setSubmitting(false);
    return;
  }

  const data = new FormData();
  formData.files.value.forEach((file) => data.append("files", file));
  data.append("shared_with_Team", String(formData.shared_with_Team.value));

  try {
    await axiosPrivate.post(
      `${environment.MOLECULELAKE_DATA_API}/pipeline/upload_file`,
      data,
      {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          if (total) {
            const progress = Math.round((loaded / total) * 100);
            setUploadProgress(progress);
          }
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    toast({
      description:
        "Your files have been uploaded successfully. Embedding is now in progress",
      status: "success",
      position: "top-right",
    });
    // Save User activity Logs
    const activity = {
      user_id: user.id,
      activity: `Uploaded ${formData.files.value.length} private literature(s) for embedding`,
    };
    saveUserActivity(activity);
    setUploadProgress(0);
    clearForm(setUploadProgress, setFormData);
    onClose();
  } catch (err: any) {
    if (err?.response?.status === 403) {
      toast({
        description:
          "You do not permissions to access this source. Redirecting to public sources ....",
        status: "warning",
        position: "top-right",
      });
      navigate(`/data/core/MoleculeLake`);
    } else {
      if (err.response?.data?.message.includes("already uploaded")) {
        toast({
          description:
            err.response?.data?.message +
            ". Please rename the file(s) before re-uploading",
          status: "warning",
          position: "top-right",
        });
      } else {
        toast({
          description: "Failed to upload files. Please try again.",
          status: err.response?.status === 400 ? "error" : "info",
          position: "top-right",
        });
      }
    }
    setUploadProgress(0);
    clearForm(setUploadProgress, setFormData);
  } finally {
    setSubmitting(false);
    setUploadProgress(0);
    clearForm(setUploadProgress, setFormData);
  }
};
