import { Box, Button, Divider, Flex, Icon, Link, Text } from "@chakra-ui/react";
import { useState } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

import { MdOutlinePayments } from "react-icons/md";
import { IoIosFlash } from "react-icons/io";
import { titleCase } from "utils/helpers";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import ChangePlanModal from "components/home/ChangePlanModal";
import InvoicesModal from "components/home/InvoicesModal";

function CurrentPlan() {
  const { user } = useSelector(selectCurrentAuthData);

  // Hooks
  const navigate = useNavigate();

  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [showInvoicesModal, setShowInvoicesModal] = useState(false);

  const planName = user?.user_type === "teams" ? "Enterprise" : user?.user_type;

  const getNextPayment = () => {
    let next = null;
    if (user && user.user_wallet.wallet > 0) {
      if (user.user_wallet.is_yearly) {
        const updated_on = new Date(user.user_wallet.updated_on);
        const year = updated_on.getFullYear();
        const month = updated_on.getMonth();
        const day = updated_on.getDate();
        const next = new Date(year + 1, month, day);
        return format(next, "MMMM d, yyyy");
      } else {
        const updated_on = new Date(user.user_wallet.updated_on);
        next = new Date(updated_on.setMonth(updated_on.getMonth() + 1));
        return format(next, "MMMM d, yyyy");
      }
    }
    return next;
  };

  return (
    <Flex gap={3} direction={"column"}>
      <Flex gap={2} align={"center"}>
        <Icon
          as={MdOutlinePayments}
          transform={"rotate(90deg)"}
          boxSize={"18px"}
          opacity={0.8}
        />
        <Text fontWeight={"600"} lineHeight={"1.4"}>
          Subscription Plan
        </Text>
      </Flex>

      <Divider />

      <Flex direction={"column"} gap={1}>
        <Flex justify="space-between">
          <Flex direction={"column"}>
            <Text
              color={"gray.500"}
              fontFamily={"Poppins, sans-serif"}
              fontSize={"18px"}
              fontWeight={"700"}
            >
              {titleCase(planName)} Plan
            </Text>
            {user.is_privileged ? (
              <Text color={"gray.500"} fontSize={"14px"} fontWeight={"500"}>
                Privileged User
              </Text>
            ) : (
              <>
                {getNextPayment() && (
                  <Flex align={"center"}>
                    <Flex direction={"row"} gap={1}>
                      <Text>Next Payment: </Text>
                      <Text color={"gray.500"} fontWeight={"500"}>
                        {getNextPayment()}
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </>
            )}
            <Flex align={"center"}>
              <Flex direction={"row"} gap={1}>
                <Button
                  mt={3}
                  py={2}
                  px={5}
                  borderRadius={"30px"}
                  bg={"highlight.primary"}
                  color={"background"}
                  h={"fit-content"}
                  w={"fit-content"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  leftIcon={<IoIosFlash size={"18px"} />}
                  letterSpacing={".02rem"}
                  _active={{ bg: "highlight.primary" }}
                  _hover={{ bg: "highlight.primary" }}
                  _focus={{ bg: "highlight.primary" }}
                  _focusWithin={{ bg: "highlight.primary" }}
                  onClick={() => navigate("/pricing")}
                >
                  Upgrade
                </Button>

                {planName !== "free" && !user.is_privileged && (
                  <Button
                    mt={3}
                    ml={3}
                    py={2}
                    px={5}
                    borderWidth={1}
                    color={"red.500"}
                    h={"fit-content"}
                    w={"fit-content"}
                    fontSize={"14px"}
                    fontWeight={"500"}
                    bg={"transparent"}
                    borderRadius={"30px"}
                    borderColor={"transparent"}
                    letterSpacing={".02rem"}
                    _active={{ borderColor: "red.500" }}
                    _hover={{ borderColor: "red.500" }}
                    _focus={{ borderColor: "red.500" }}
                    _focusWithin={{ borderColor: "red.500" }}
                    onClick={() => setShowChangePlanModal(true)}
                  >
                    Cancel Plan
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex gap={1}>
            <Box>
              <Flex align={"center"}>
                <Flex
                  direction={"row"}
                  align={"center"}
                  color="highlight.primary"
                  gap={1}
                >
                  <Link onClick={() => setShowInvoicesModal(true)}>
                    Invoices
                  </Link>
                  <FaArrowRight />
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <ChangePlanModal
        isOpen={showChangePlanModal}
        onClose={() => {
          setShowChangePlanModal(false);
        }}
        newPlan="Free"
        isAnnual={false}
      />
      <InvoicesModal
        isOpen={showInvoicesModal}
        onClose={() => setShowInvoicesModal(false)}
      />
    </Flex>
  );
}

export default CurrentPlan;
