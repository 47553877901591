import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  useTheme,
  Text,
} from "@chakra-ui/react";

// icons
import {
  MdLogout,
  MdOutlinePrivacyTip,
  MdOutlinePayments,
} from "react-icons/md";
import { LuMenu } from "react-icons/lu";
import { FaCode } from "react-icons/fa";
import { RiUserSettingsLine } from "react-icons/ri";

import UserAvatar from "components/ui/UserAvatar";
import useGuideTour from "hooks/guideTour/useGuideTour";
import PrivacyPolicyModal from "components/home/PrivacyPolicyModal";
import AccountSettingsModal from "components/userMenu/AccountSettingsModal";

import { ThemeProps } from "components/userMenu/Appearance";
import { hexToRgba } from "utils/helpers";

import useLogout from "hooks/auth/useLogout";

interface ProfileUserProps {
  email?: string;
  given_name?: string;
  family_name?: string;
  sub?: string;
  id?: string;
}

interface UserProps {
  user: ProfileUserProps;
}

export default function AvatarMenu({ onToggle, isNavOpen }: ThemeProps) {
  const { isHighlighted } = useGuideTour();
  let highlighted = isHighlighted("user_menu");

  // State
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);

  const navigate = useNavigate();

  const menuItems = useMemo(
    () => [
      {
        name: "Account Settings",
        icon: RiUserSettingsLine,
        handler: () => setShowAccountModal(true),
        tooltip: "Account Settings",
      },
      {
        name: "Change Your Plan",
        icon: MdOutlinePayments,
        handler: () => navigate("/pricing"),
        tooltip: "Upgrade Plan",
      },
      {
        name: "API Documentation",
        icon: FaCode,
        handler: () => window.open(`${window.location.origin}/docs`),
        tooltip: "API Documentation",
      },
      {
        name: "Privacy Policy",
        icon: MdOutlinePrivacyTip,
        handler: () => setShowPrivacyModal(true),
        tooltip: "Privacy Policy",
      },
    ],
    [navigate]
  );

  // Auth
  const { user } = useSelector(selectCurrentAuthData);

  // Theme
  const { colors } = useTheme();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const bgColor = isLight ? "neutral.200" : "lightBackground";

  return (
    <Flex
      id="user_menu"
      zIndex={highlighted ? 2000 : "initial"}
      borderRadius={"6"}
      fontWeight={highlighted ? 600 : "initial"}
      bg={highlighted ? "background" : "transparent"}
      _hover={{
        bg: highlighted ? hexToRgba(colors.primary[500], 0.06) : "transparent",
      }}
      pointerEvents={highlighted ? "none" : "auto"}
    >
      {/* Avatar & Menu Icon */}
      <Flex align={"center"} justify={"space-between"} w={"100%"}>
        {/* Menu */}
        <Menu placement={"top-end"}>
          <MenuButton
            h={"fit-content"}
            w={"100%"}
            borderRadius={"6px"}
            color={"primary.500"}
            bg={"transparent"}
            transition={"all .3s ease"}
            _hover={{ bg: bgColor }}
            p={1}
          >
            <Flex align={"center"} justify={"space-between"}>
              {/* Avatar */}
              <UserAvatar isNavOpen={isNavOpen} />

              {isNavOpen && (
                <Icon as={LuMenu} boxSize={8} p={2} color={"gray.500"} />
              )}
            </Flex>
          </MenuButton>

          <MenuList
            bg={"background"}
            borderColor={hexToRgba(colors.secondary[700], 0.2)}
            borderRadius={16}
            p={2}
            zIndex={999}
          >
            <UserDetails user={user ?? {}} />

            {menuItems.map((item, index) => {
              if (item.name === "API Documentation" && !user.is_internal) {
                // eslint-disable-next-line array-callback-return
                return;
              }
              return (
                <MenuItem
                  key={"item-" + index}
                  bg={"transparent"}
                  color={"gray.500"}
                  _hover={{ bg: hexToRgba(colors.blue[500], 0.12) }}
                  onClick={item.handler}
                  display={"flex"}
                  alignItems={"center"}
                  gap={4}
                  py={3}
                  borderRadius={8}
                >
                  <Icon as={item.icon} boxSize="16px" />
                  <Text fontSize={"14px"} fontWeight={"500"}>
                    {item.name}
                  </Text>
                </MenuItem>
              );
            })}

            <MenuDivider />
            <Logout />
          </MenuList>
        </Menu>
      </Flex>

      {/* Modals */}
      <PrivacyPolicyModal
        isOpen={showPrivacyModal}
        onClose={() => setShowPrivacyModal(false)}
      />

      <AccountSettingsModal
        isOpen={showAccountModal}
        onClose={() => setShowAccountModal(false)}
        themeOnToggle={onToggle}
      />
    </Flex>
  );
}

function UserDetails({ user }: UserProps) {
  const { given_name, family_name, email } = user;

  return (
    <Flex direction={"column"} gap={1} mt={1} mb={4} px={3}>
      {family_name && given_name ? (
        <Text
          textTransform={"capitalize"}
          fontWeight={"bold"}
          fontSize={"14px"}
          color={"blue.700"}
        >
          {`${given_name} ${family_name}`}
        </Text>
      ) : null}
      {email ? (
        <Text color={"blue.600"} fontSize={"12px"}>
          {email}
        </Text>
      ) : null}
    </Flex>
  );
}

function Logout() {
  // Theme
  const { colors } = useTheme();
  const { logout } = useLogout();

  const handleLogout = async () => await logout();

  return (
    <MenuItem
      bg={"transparent"}
      color={"gray.500"}
      _hover={{ bg: hexToRgba(colors.blue[500], 0.12) }}
      onClick={handleLogout}
      gap={2}
      borderRadius={"6px"}
    >
      <Icon as={MdLogout} boxSize="16px" />
      <Text fontSize={"14px"} fontWeight={"500"}>
        Log out
      </Text>
    </MenuItem>
  );
}
