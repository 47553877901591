import { useState, KeyboardEvent, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/bookmarks/savedSlice";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { selectCurrentPostsData } from "redux/features/posts/postsSlice";

import { Button, Text, Flex, Image, useToast } from "@chakra-ui/react";

import { environment } from "environments";
import { SavedFolderProps } from "models/bookmarks/SavedProps";
import CreateNewFolderModal from "./modal/CreateNewFolderModal";

import noFolders from "assets/no-folders.png";
import { TbFolderPlus } from "react-icons/tb";
import { useUsersAPI } from "api/useUsersAPI";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

interface NewFolderContentProps {
  hasFolders?: boolean;
  inView?: boolean;
  small?: boolean;
}

function NewFolderContent({
  hasFolders,
  inView,
  small,
}: NewFolderContentProps) {
  const { user } = useSelector(selectCurrentAuthData);

  // APIs
  const { saveUserActivity } = useUsersAPI();

  // Hooks
  const toast = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  let { teamData } = useSelector(selectCurrentPostsData);
  const { savedFolders } = useSelector(selectCurrentSavedData);

  // States
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  // Handler
  async function handleCreateFolder(newFolderName: string) {
    if (!newFolderName) return;
    setCreatingFolder(false);

    try {
      setCreatingFolder(true);

      const response = await axiosPrivate.post(
        `${environment.BACKEND_API}/api/saved_elements`,
        {
          name: newFolderName,
          teamId: window.location.pathname.includes("team")
            ? teamData.id
            : null,
        }
      );

      const newFolder = response.data as SavedFolderProps;

      // Save User activity log
      const activity = {
        user_id: user.id,
        activity: `Created new bookmark folder: ${newFolder.name}`,
      };
      await saveUserActivity(activity);

      // Sort bookmarks alphabetically (asc.)
      const sortedFolders = [...savedFolders, newFolder]?.sort(
        (a: SavedFolderProps, b: SavedFolderProps) => {
          const a_name: string | undefined = a?.name;
          const b_name: string | undefined = b?.name;

          if (a_name && b_name) {
            return a_name.localeCompare(b_name);
          }
          return 1;
        }
      );

      dispatch(setSuccess(sortedFolders));
      setIsOpen(false);
    } catch (error: any) {
      // NOTE: type not provided for error object
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 1500,
        position: "top-right",
      });
    } finally {
      setCreatingFolder(false);
      if (inputRef?.current) {
        inputRef.current.value = "";
      }
    }
  }

  return (
    <Flex
      direction={"column"}
      justify={"center"}
      align={"center"}
      gap={8}
      w={"100%"}
      py={0}
      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          if (inputRef?.current?.value) {
            handleCreateFolder(inputRef?.current?.value.trim());
          }
        }
      }}
    >
      {/* image & no folder text */}
      {!hasFolders && !inView && !small && (
        <Flex direction={"column"} gap={2} align={"center"}>
          <Image
            src={noFolders}
            w={"42px"}
            h={"42px"}
            objectFit={"contain"}
            alt="no folders"
            loading="lazy"
          />
          <Text
            color={"blue.700"}
            fontSize={"14px"}
            fontWeight={"600"}
            lineHeight={"1.2"}
          >
            No folders found
          </Text>
        </Flex>
      )}

      <Flex direction={"column"} gap={1} w={"100%"}>
        {/* input & new folder button */}
        <Flex
          w={"100%"}
          justifyContent={"flex-end"}
          gap={2}
          direction={inView ? "column" : "row"}
        >
          <Button
            color={"secondary.500"}
            bg={"transparent"}
            border={"none"}
            px={6}
            h={small ? 8 : 10}
            fontSize={small ? "12px" : "14px"}
            fontWeight={"600"}
            fontFamily={"Poppins, sans-serif"}
            letterSpacing={".05rem"}
            textTransform={"uppercase"}
            loadingText={"Creating..."}
            isLoading={creatingFolder}
            leftIcon={<TbFolderPlus size={small ? "14px" : "16px"} />}
            _hover={{ color: "secondary.700", bg: "transparent" }}
            _focus={{ color: "secondary.700", bg: "transparent" }}
            _active={{ color: "secondary.700", bg: "transparent" }}
            onClick={() => setIsOpen(true)}
          >
            Create Folder
          </Button>

          <CreateNewFolderModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleCreateFolder={handleCreateFolder}
            creatingFolder={creatingFolder}
            small={small}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default NewFolderContent;
