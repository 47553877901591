import {
  KeyboardEvent,
  useRef,
  useState,
  ChangeEvent,
  useEffect,
  useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Select, { StylesConfig } from "react-select";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { selectCurrentPostsData } from "redux/features/posts/postsSlice";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Icon,
  Flex,
  Text,
  Box,
  useTheme,
  useToast,
  UnorderedList,
  ListItem,
  ListIcon,
  Card,
  CardBody,
  Heading,
  Stack,
  Avatar,
  Progress,
  Tooltip,
  ModalFooter,
} from "@chakra-ui/react";

import SourceUpload from "./FilesUploader";
import FilesModal from "./ViewUploadedFiles";
import { CheckboxStyled } from "components/checkbox/Checkbox";
import { hexToRgba } from "utils/helpers";

import { MdClose } from "react-icons/md";
import { TbCheck, TbFile, TbShare } from "react-icons/tb";
import {
  CollapseAll,
  handleDragFiles,
  handleDropFiles,
  handleFileChange,
  handleFileValidation,
  handleInputChange,
  handleRemoveFile,
  handleTeamSelection,
  handleUploadFiles,
  handleViewFile,
  loadMoreFiles,
} from "./helpers";
import { truncateString } from "components/ui/helpers";
import { useUsersAPI } from "api/useUsersAPI";

interface NewSourceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface Option {
  label: string;
  value: string;
  id: string;
  isSelected: boolean;
}

export interface FormDataProps {
  shared_with_Team: FieldProps<boolean>;
  files: FieldProps<any[]>;
  has_access: FieldProps<string[]>;
}

interface FieldProps<T> {
  value: T;
  invalid: boolean;
  error: string;
}

const MAX_TOTAL_SIZE = 1024; // Max total file size in MB for all files
const MAX_FILE_COUNT = 10; // Max number of files

export default function NewSourceModal({
  isOpen,
  onClose,
}: NewSourceModalProps) {
  // APIs
  const { saveUserActivity } = useUsersAPI();

  // Hooks
  const toast = useToast();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { user } = useSelector(selectCurrentAuthData);
  let { teamData } = useSelector(selectCurrentPostsData);

  const location = useLocation();
  const pathName = location.pathname;

  // States
  const [visibleFilesCount, setVisibleFilesCount] = useState(3);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [formData, setFormData] = useState<FormDataProps>({
    shared_with_Team: {
      value: pathName === "/team/literature" ? true : false,
      invalid: false,
      error: "",
    },
    files: { value: [], invalid: false, error: "" },
    has_access: { value: [], invalid: false, error: "" },
  });
  const [openShowFilesModal, setOpenShowFilesModal] = useState(false);
  const [connectedTeams] = useState(() => {
    return teamData ? [teamData] : [];
  });

  // Theme
  const { colors } = useTheme();

  const teams: Option[] = (teamData ? [teamData] : []).map((team: any) => ({
    label: team.name,
    value: team.name,
    id: team.owner_id,
    isSelected: formData.has_access.value.includes(team.name),
  }));

  const selectInputStyle: StylesConfig<Option, true> = useMemo(
    () => ({
      control: (base: any) => ({
        ...base,
        fontSize: "12px",
        marginTop: "10px",
        borderColor: hexToRgba(colors.primary[600], 0.1),
        ":focus": {
          borderColor: hexToRgba(colors.primary[600], 0.1),
        },
        "&:hover": { cursor: "pointer" },
      }),
      multiValueLabel: (base) => ({
        ...base,
        backgroundColor: hexToRgba(colors.blue[600], 0.1),
        color: "hightlight.primary",
      }),
      multiValueRemove: (base) => ({
        ...base,
        backgroundColor: hexToRgba(colors.primary[600], 0.1),
        color: "hightlight.primary",
      }),
      placeholder: (base) => ({
        ...base,
        fontSize: "12px",
        color: colors.gray[400],
      }),

      option: (base, { isSelected }: { isSelected: boolean }) => ({
        ...base,
        paddingLeft: 10,
        fontSize: "12px",
        fontWeight: isSelected ? "500" : "400",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: isSelected
          ? hexToRgba(colors.highlight.primary, 0.25)
          : "transparent",
        color: isSelected ? colors.primary[100] : colors.primary[800],
        "&:hover": {
          backgroundColor: isSelected
            ? hexToRgba(colors.highlight.primary, 0.14)
            : hexToRgba(colors.black, 0.04),
          color: isSelected ? colors.primary[100] : colors.primary[800],
          cursor: "pointer",
        },
      }),
    }),
    [colors]
  );

  // Function to render files
  const renderFiles = (files: File[]) => {
    return files
      .slice(0, visibleFilesCount)
      .map((file: File, index: number) => (
        <UnorderedList styleType="none">
          <ListItem key={index} mb="20px" display="flex">
            <Tooltip
              label={file.name}
              placement={"bottom"}
              bg={"gray.900"}
              color={"gray.100"}
              hasArrow
              py={2}
              px={3}
              m={2}
              maxW={["480px"]}
              borderRadius={"6px"}
              fontSize={"12px"}
              boxShadow={"none"}
              arrowSize={8}
            >
              <Text
                color={colors.blue[600]}
                onClick={() => handleViewFile(file)}
              >
                {truncateString(file.name, 60)}
              </Text>
            </Tooltip>
            <ListIcon
              as={MdClose}
              color={colors.red[500]}
              ml="20px"
              onClick={() => handleRemoveFile(file.name, formData, setFormData)}
            />
          </ListItem>
        </UnorderedList>
      ));
  };

  useEffect(() => {
    if (formData.files.value.length === 0) {
      setOpenShowFilesModal(false);
      setVisibleFilesCount(3);
    }
  }, [formData.files.value]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf="center"
        p={1}
        w="fit-content"
        minW={dragActive ? "800px" : "600px"}
        minH={dragActive ? "500px" : "300px"}
        borderRadius="6px"
        bg={dragActive ? "transparent" : "background"}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter")
            handleUploadFiles(
              axiosPrivate,
              formData,
              setFormData,
              setSubmitting,
              setUploadProgress,
              onClose,
              toast,
              colors,
              MAX_TOTAL_SIZE,
              navigate,
              user,
              saveUserActivity
            );
        }}
        onDragEnter={(e) => handleDragFiles(e, setDragActive)}
        onDragLeave={(e) => handleDragFiles(e, setDragActive)}
        onDragOver={(e) => handleDragFiles(e, setDragActive)}
      >
        {!dragActive && (
          <>
            <ModalHeader display="flex" alignItems="center" gap={2}>
              <Icon
                as={TbFile}
                bg="highlight.primary"
                color="gray.50"
                boxSize="28px"
                borderRadius="6px"
                p="3px"
              />
              <Text fontSize="16px" color="gray.600" fontWeight="500">
                Add a new data source
              </Text>
            </ModalHeader>
            <ModalBody>
              <SourceUpload
                dragActive={dragActive}
                valid={formData.files.invalid}
                accept="application/pdf"
                multiple
                handleFileChange={(e) =>
                  handleFileChange(e, (files) =>
                    handleFileValidation(
                      files,
                      formData,
                      setFormData,
                      MAX_FILE_COUNT
                    )
                  )
                }
                onButtonClick={() => inputRef.current?.click()}
                error={formData.files.error}
                handleDrag={(e) => handleDragFiles(e, setDragActive)}
                handleDrop={(e) =>
                  handleDropFiles(e, setDragActive, (files) =>
                    handleFileValidation(
                      files,
                      formData,
                      setFormData,
                      MAX_FILE_COUNT
                    )
                  )
                }
              >
                <Text color={colors.white}>Upload</Text>
              </SourceUpload>
              {formData.files.value.length > 0 &&
                renderFiles(formData.files.value)}

              {visibleFilesCount < 5 && formData.files.value.length >= 5 && (
                <Button
                  mb={"30px"}
                  ml={"20px"}
                  size={"sm"}
                  variant="outline"
                  bg={"blue.50"}
                  onClick={() => loadMoreFiles(setVisibleFilesCount)}
                  color={"blue.400"}
                  fontWeight={"400"}
                  alignSelf={"flex-end"}
                >
                  Load more
                </Button>
              )}

              {visibleFilesCount > 5 && formData.files.value.length > 6 && (
                <Button
                  mb={"30px"}
                  ml={"20px"}
                  size={"sm"}
                  variant="outline"
                  bg={"blue.50"}
                  onClick={() => CollapseAll(setOpenShowFilesModal)}
                  color={"blue.400"}
                  fontWeight={"400"}
                  alignSelf={"flex-end"}
                >
                  Show all files
                </Button>
              )}

              <FilesModal
                isOpen={openShowFilesModal}
                files={formData.files.value}
                onClose={() => setOpenShowFilesModal(false)}
                handleRemoveFile={(fileName) =>
                  handleRemoveFile(fileName, formData, setFormData)
                }
              />

              {user?.user_type === "teams" && (
                <>
                  <Flex>
                    <Icon
                      as={TbShare}
                      bg=""
                      color={"highlight.primary"}
                      boxSize="28px"
                      borderRadius="6px"
                      p="3px"
                      mr={"10px"}
                    />
                    <Text
                      fontSize="16px"
                      mr="15px"
                      color={colors.gray[600]}
                      mb={"10px"}
                    >
                      Share with:{" "}
                    </Text>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"center"}
                    >
                      {pathName !== "/team/literature" && (
                        <Box>
                          <CheckboxStyled
                            large
                            roundedFull
                            forEmbeddings
                            isChecked={!formData.shared_with_Team.value}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleInputChange(
                                "shared_with_Team",
                                false,
                                setFormData,
                                connectedTeams
                              )
                            }
                          >
                            <Text
                              fontSize="16px"
                              mr="15px"
                              color={colors.gray[500]}
                              flexDirection={"row"}
                              display={"flex"}
                            >
                              Just me <Text color={colors.gray[400]}></Text>
                            </Text>
                          </CheckboxStyled>
                        </Box>
                      )}
                      <Box mt={"2px"}>
                        <CheckboxStyled
                          large
                          roundedFull
                          forEmbeddings
                          isChecked={formData.shared_with_Team.value}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(
                              "shared_with_Team",
                              true,
                              setFormData,
                              connectedTeams
                            )
                          }
                        >
                          <Text
                            fontSize="14px"
                            mr="15px"
                            color={colors.gray[500]}
                            flexDirection={"row"}
                            display={"flex"}
                            mt={"0"}
                          >
                            My team(s) <Text color={colors.gray[400]}></Text>
                          </Text>
                        </CheckboxStyled>
                      </Box>
                    </Box>
                  </Flex>

                  {formData.shared_with_Team.value &&
                    connectedTeams?.length >= 1 && (
                      <Card
                        p={"10px"}
                        mt={"10px"}
                        ml={"40px"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        borderRadius={"6px"}
                        borderWidth={1}
                        borderColor={hexToRgba(colors.highlight.primary, 0.16)}
                        bg={hexToRgba(colors.highlight.primary, 0.12)}
                        direction={{ base: "column", sm: "row" }}
                        overflow="hidden"
                        variant="outline"
                        _hover={{
                          textDecoration: "none",
                          bg: hexToRgba(colors.highlight.primary, 0.12),
                        }}
                      >
                        <Avatar
                          fontSize={"16px"}
                          w={"40px"}
                          h={"40px"}
                          name={connectedTeams[0]?.name}
                          src=""
                        />
                        <Stack w={"100%"}>
                          <CardBody pl={"20px"} p={0}>
                            <Heading size="sm" fontWeight={"500"}>
                              {" "}
                              {connectedTeams[0]?.name}
                            </Heading>
                            <Text color={"gray.500"}>
                              members: {connectedTeams[0]?.members.length}
                            </Text>
                          </CardBody>
                        </Stack>
                        <Icon
                          as={TbCheck}
                          bg="transparent"
                          color={"highlight.primary"}
                          boxSize="28px"
                          borderRadius="6px"
                          p="3px"
                        />
                      </Card>
                    )}
                  {formData.shared_with_Team.value &&
                    connectedTeams?.length > 1 && (
                      <Select
                        styles={selectInputStyle}
                        placeholder={"Please select teams"}
                        options={teams}
                        isSearchable={true}
                        isMulti={true}
                        defaultValue={[teams[0]]}
                        isDisabled={connectedTeams?.length === 1}
                        onChange={(newValue) =>
                          handleTeamSelection(newValue, setFormData)
                        }
                        noOptionsMessage={() => (
                          <Text fontSize="sm">No more teams to select</Text>
                        )}
                      />
                    )}
                </>
              )}

              {uploadProgress > 0 && formData.files.value.length > 0 && (
                <Flex
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  marginTop={30}
                >
                  <Progress
                    width={"92%"}
                    marginTop={"5px"}
                    marginRight={"20px"}
                    borderRadius={5}
                    size="md"
                    hasStripe
                    value={uploadProgress}
                  />
                  <Text
                    alignSelf={"center"}
                    fontWeight={"500"}
                    fontSize={"14px"}
                    letterSpacing={".05rem"}
                    width={"8%"}
                    textAlign={"right"}
                  >
                    {uploadProgress}%
                  </Text>
                </Flex>
              )}
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} mr={10} bg={"gray.100"}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() =>
                  handleUploadFiles(
                    axiosPrivate,
                    formData,
                    setFormData,
                    setSubmitting,
                    setUploadProgress,
                    onClose,
                    toast,
                    colors,
                    MAX_TOTAL_SIZE,
                    navigate,
                    user,
                    saveUserActivity
                  )
                }
                isLoading={submitting}
                loadingText="Uploading..."
                disabled={formData.files.error !== ""}
              >
                Upload
              </Button>
            </ModalFooter>
          </>
        )}
        {dragActive && (
          <Box
            borderRadius="10px"
            borderStyle="dashed"
            borderColor={"highlight.primary"}
            borderWidth={3}
            backgroundColor={colors.gray[100]}
            justifyContent="center"
            textAlign="center"
            onDragEnter={(e) => handleDragFiles(e, setDragActive)}
            onDragLeave={(e) => handleDragFiles(e, setDragActive)}
            onDragOver={(e) => handleDragFiles(e, setDragActive)}
            onDrop={(e) =>
              handleDropFiles(e, setDragActive, (files: any) =>
                handleFileValidation(
                  files,
                  formData,
                  setFormData,
                  MAX_FILE_COUNT
                )
              )
            }
            position="absolute"
            top={0}
            left={0}
            right={0}
            zIndex={999}
            minW={dragActive ? "800px" : "100%"}
            minH={dragActive ? "500px" : "100%"}
          />
        )}
      </ModalContent>
    </Modal>
  );
}
