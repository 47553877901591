import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/bookmarks/savedSlice";

import {
  Flex,
  Box,
  SimpleGrid,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";

import MainPanelError from "components/ui/MainPanelError";
import { errorHandler } from "utils/helpers";
import Loading from "components/ui/Loading";

import { SavedFolderProps } from "models/bookmarks/SavedProps";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { useMemo, useState } from "react";
import { environment } from "environments";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import NoFolders from "components/library/bookmarks/NoFolders";
import FoldersItem from "components/library/bookmarks/FoldersItem";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useUsersAPI } from "api/useUsersAPI";

export default function FoldersList() {
  const { user } = useSelector(selectCurrentAuthData);

  // APIs
  const { saveUserActivity } = useUsersAPI();

  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { loading, error, savedFolders } = useSelector(selectCurrentSavedData);

  // States
  const [deleting, setDeleting] = useState(false);
  const [updatingFolder, setUpdatingFolder] = useState(false);
  const [renameModal, setRenameModal] = useState<{
    isOpen: boolean;
    folder?: SavedFolderProps;
  }>({ isOpen: false });

  const { hasFoldersToShow, foldersToShow } = useMemo(() => {
    let foldersToShow: SavedFolderProps[] = [];
    if (window.location.pathname.includes("team")) {
      foldersToShow = savedFolders?.filter((x) => x?.isShared === true);
    } else {
      foldersToShow = savedFolders;
    }
    return {
      hasFoldersToShow: foldersToShow?.length > 0,
      foldersToShow: foldersToShow,
    };
  }, [savedFolders]);

  function handleOpenRenameModal(folder: SavedFolderProps) {
    setRenameModal({ isOpen: true, folder });
  }

  function handleCloseRenameModal() {
    setRenameModal({ isOpen: false });
  }

  // Handlers
  async function handleDeleteFolder(folderId: string) {
    if (!folderId) return;

    setDeleting(false);

    try {
      setDeleting(true);

      const folderToDelete = foldersToShow?.filter(
        (folder: SavedFolderProps) => folder.id === folderId
      )[0];

      await axiosPrivate.delete(
        `${environment.BACKEND_API}/api/remove_elements?folderId=${folderId}`
      );

      // Save User activity log
      const activity = {
        user_id: user.id,
        activity: `Deleted bookmark folder: ${folderToDelete.name}`,
      };
      await saveUserActivity(activity);

      // update store
      let newFolders: SavedFolderProps[] = foldersToShow?.filter(
        (folder: SavedFolderProps) => folder.id !== folderId
      );
      dispatch(setSuccess(newFolders));

      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Folder deleted successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        title: "Error Deleting Folder",
        status: "error",
        duration: 1500,
      });
    } finally {
      setDeleting(false);
    }
  }

  async function handleUpdateFolderTitle(title: string, id: string) {
    if (!id) return;

    setUpdatingFolder(true);

    try {
      setUpdatingFolder(true);

      await axiosPrivate.put(
        `${environment.BACKEND_API}/api/remove_elements?folderId=${id}`,
        { newName: title, folderId: id }
      );

      setUpdatingFolder(false);
      let updatedFolders: SavedFolderProps[] = foldersToShow?.map(
        (folder: SavedFolderProps) =>
          folder.id === id ? { ...folder, name: title } : folder
      );
      setRenameModal({ isOpen: false });
      dispatch(setSuccess(updatedFolders));
      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Folder updated successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        title: "Error updating Folder",
        status: "error",
        duration: 1500,
      });
    } finally {
      setUpdatingFolder(false);
    }
  }

  function handleOpenFolder(id: string) {
    const currentSection = window.location.pathname.includes("team")
      ? "/team/folders"
      : "/data/bookmarks";
    navigate(`${currentSection}/${id}`);
  }

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: to do not delete calculations below
  const listHeight = useBreakpointValue({
    lg: "calc(100vh - 154px)", // 150 = (8x2) + 62 + 24 + 32 + 16 : py + nav + gap + breadcrumb
    xl: "calc(100vh - 162px)", // 158 = (12x2) + 62 + 24 + 32 + 16
    "2xl": "calc(100vh - 178px)", // 174 = (24x2) + 62 + 24 + 32 + 16
  });

  // Style
  const listStyle = {
    height: listHeight,
    width: "100%",
    padding: "6px 12px 6px 0",
  };

  if (!!error) {
    return (
      <Flex h={listHeight} w={"100%"}>
        <MainPanelError errorMessage={errorHandler(error).message} />
      </Flex>
    );
  }

  if (!!loading) {
    return (
      <Flex h={listHeight} w={"100%"}>
        <Loading message="Loading folders.." />
      </Flex>
    );
  }

  return (
    <>
      {hasFoldersToShow ? (
        <CustomScrollBar style={listStyle}>
          <SimpleGrid columns={3} spacing={4}>
            {foldersToShow?.map((folder: SavedFolderProps) => {
              return (
                <FoldersItem
                  key={"folder-" + folder.id}
                  folder={folder}
                  deleting={deleting}
                  onDeleteFolder={handleDeleteFolder}
                  onUpdateFolderTitle={handleUpdateFolderTitle}
                  updatingFolder={updatingFolder}
                  onClick={() => handleOpenFolder(folder.id)}
                  renameModal={renameModal}
                  onOpenRenameModal={handleOpenRenameModal}
                  onCloseRenameModal={handleCloseRenameModal}
                />
              );
            })}
          </SimpleGrid>
        </CustomScrollBar>
      ) : (
        <NoFolders />
      )}
    </>
  );
}
