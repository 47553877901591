import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import useAllowAccess from "hooks/auth/useAllowAccess";

interface RequireAuthProps {
  allowedTypes: string[];
  allowedPermissions?: string[];
  allowedAttribute?: boolean;
}

const RequireAuth = ({
  allowedTypes,
  allowedPermissions,
  allowedAttribute = false,
}: RequireAuthProps) => {
  const { user } = useSelector(selectCurrentAuthData);
  const { userHasRequiredPermissions, userIsAnAllowedTypes } = useAllowAccess();
  const location = useLocation();

  const isPermitted = allowedPermissions
    ? userHasRequiredPermissions(allowedPermissions)
    : false;

  const hasAccess = user && (userIsAnAllowedTypes(allowedTypes) || isPermitted);

  // NOTE: Use 'allowedAttribute' for docs api page only
  if (allowedAttribute) {
    return user ? (
      <Outlet />
    ) : (
      <Navigate
        to={user ? "/unauthorized" : "/login"}
        state={{ from: location }}
        replace
      />
    );
  }

  return hasAccess ? (
    <Outlet />
  ) : (
    <Navigate
      to={user ? "/unauthorized" : "/login"}
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
