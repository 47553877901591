import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

interface Activity {
  user_id?: string;
  activity: string;
}

export const useUsersAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  const saveUserActivity = async (payload: Activity) => {
    const response = await axiosPrivate.post(
      `/admin_dashboard/api/admin_activities`,
      payload
    );
    return response.data;
  };

  return {
    saveUserActivity,
  };
};
