import { useEffect, useState } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useStripeAPI } from "services/stripe.service";
import MainPanelError from "components/ui/MainPanelError";
import Loading from "components/ui/Loading";
import { errorHandler } from "utils/helpers";
import InvoiceTemplate from "./InvoiceTemplate";

interface InvoicesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function InvoicesModal({ isOpen, onClose }: InvoicesModalProps) {
  // States
  const [invoices, setInvoices] = useState<null | []>(null);
  const [isLoading, setIsLoading] = useState(false);

  // APIs
  const { getUserInvoices } = useStripeAPI();

  useEffect(() => {
    const getInvoices = async () => {
      setIsLoading(true);
      try {
        const res = await getUserInvoices();
        setInvoices(res);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        bg={"background"}
        alignSelf={"center"}
        overflowY={"auto"}
        minW={{ base: "90%", lg: "800px" }}
        maxW={{ base: "90%", lg: "800px" }}
        h={{
          lg: "calc(100vh - 16px)",
          xl: "calc(100vh - 24px)",
          "2xl": "calc(100vh - 32px)",
        }}
        my={"auto"}
      >
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          color={"gray.700"}
          fontFamily={"Poppins, sans-serif"}
          justifyContent={"space-between"}
          borderBottomWidth={1}
          borderBottomColor={"gray.200"}
        >
          {/* header */}
          <Text fontSize={"16px"} fontWeight={"500"}>
            Invoices
          </Text>
        </ModalHeader>

        <ModalBody px={8} py={8} width={"100%"}>
          <Flex gap={10} direction={"column"} color={"secondary.700"}>
            {/* General section */}
            <Flex gap={2} direction={"column"}>
              {isLoading ? (
                <Loading message="Loading invoices..." />
              ) : (
                <>
                  {invoices ? (
                    <>
                      {invoices?.length === 0 && (
                        <Text color={"gray.500"}>No available invoices</Text>
                      )}

                      {invoices?.length > 0 && (
                        <InvoiceTemplate invoices={invoices} />
                      )}
                    </>
                  ) : (
                    <Flex h={"100%"} w={"100%"}>
                      <MainPanelError
                        errorMessage={errorHandler(null).message}
                      />
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
