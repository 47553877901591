import React, { useState, ChangeEvent, useRef } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  InputRightElement,
  InputGroup,
  useToast,
  Icon,
  Image,
  Text,
  Flex,
  Spinner,
  FormHelperText,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import {
  getName,
  hexToRgba,
  validateEmail,
  validateFullName,
  validatePass,
} from "utils/helpers";
import { confirmSignUp, signUp } from "services/user.service";

import { axiosClient } from "api/axios";
import { useDispatch } from "react-redux";
import { setAccessToken, setUser } from "redux/features/auth/authSlice";
import { environment } from "environments";

import googleImg from "assets/login/google.svg";
import { useUsersAPI } from "api/useUsersAPI";

export default function Register({
  setFormData,
  formData,
}: {
  setFormData: (data: {
    email: string;
    fullname: string;
    password: string;
  }) => void;
  formData: {
    email: string;
    fullname: string;
    password: string;
  };
}) {
  // APIs
  const { saveUserActivity } = useUsersAPI();

  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const fullnameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = useState("");

  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [userSub, setUserSub] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const [redirecting, setRedirecting] = useState(false);

  // Handlers
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrorMsg("");
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const isFormValid = () => {
    if (!validateFullName(formData.fullname)) {
      setErrorMsg(
        "Please provide your fullname in the format: firstname lastname"
      );
      if (fullnameRef.current !== null) fullnameRef.current.focus();
      return false;
    }

    if (!validateEmail(formData.email)) {
      setErrorMsg("Please provide a valid email address");
      if (emailRef.current !== null) emailRef.current.focus();
      return false;
    }

    if (validatePass(formData.password).length > 0) {
      setErrorMsg(validatePass(formData.password));
      if (passwordRef.current !== null) passwordRef.current.focus();
      return false;
    }

    setErrorMsg("");
    return true;
  };

  const handleSubmit = async () => {
    if (!isFormValid()) return;

    const payload = {
      email: formData.email.trim(),
      firstname: getName("first", formData.fullname.trim()),
      lastname: getName("last", formData.fullname.trim()),
      password: formData.password,
    };

    setSubmitting(true);
    await signUp(payload)
      .then(async (res) => {
        setUserSub(res.user_sub);
        setSubmitting(false);
        setStep(2);
      })
      .catch((error) => {
        console.log(error);
        toast({
          description: error.response.data.message
            ? error.response.data.message
            : "Something went wrong while signing up. Please try again",
          status: "error",
          position: "top-right",
        });
        setSubmitting(false);
      });
  };

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  function handleCodeKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleVerify();
    }
  }

  const handleVerify = async () => {
    if (code.length < 6) {
      setErrorMsg("Code needs to be 6 characters long at least");

      return;
    }

    const payload = {
      code: code,
      user_sub: userSub,
      email: formData.email.trim(),
    };

    setIsVerifying(true);

    await confirmSignUp(payload)
      .then(async (res) => {
        toast({
          description: "Registration successfully",
          status: "success",
          position: "top-right",
        });
        setIsVerifying(false);
        loginUser(payload.email, formData.password);
      })
      .catch((error) => {
        const verifyingError = error.response.data.message
          ? error.response.data.message
          : "Something went wrong while confirming sign up";

        setErrorMsg(verifyingError);

        setIsVerifying(false);
      });
  };

  const loginUser = async (username: string, password: string) => {
    try {
      setIsSigningIn(true);

      const response = await axiosClient.post(
        "/api/auth",
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const { user, accessToken } = response?.data;

      dispatch(setUser(user));
      dispatch(setAccessToken(accessToken));
      // Save User activity Logs
      const activity = {
        user_id: user.id,
        activity: "New user sign up. Self sign up",
      };
      await saveUserActivity(activity);
    } catch (error: any) {
      // No response from server
      if (!error.response) {
        toast({
          description: "No server response!",
          status: "error",
        });
        setIsSigningIn(false);
        return;
      }
      toast({
        description: error.response.data.message,
        status: "error",
      });
      setIsSigningIn(false);
    } finally {
      setIsSigningIn(false);
    }
  };

  const thirdPartySignIn = () => {
    setRedirecting(true);
    const redirectUri = encodeURIComponent(environment.AUTH_REDIRECT_URI);
    const OAUTH2_URL = `${environment.AUTH_DOMAIN}/oauth2/authorize?client_id=${environment.APP_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${redirectUri}&prompt=select_account`;
    window.open(OAUTH2_URL, "_self");
  };

  return (
    <>
      {step === 1 ? (
        <Flex direction={"column"} gap={4} w="100%" maxW="460px">
          <Flex direction="column" gap={4} w="100%">
            <FormControl id={"fullname"} isRequired w={"100%"}>
              <FormLabel
                htmlFor={"fullname"}
                color={"secondary.700"}
                fontSize={"12px"}
                fontWeight={"500"}
                fontFamily={"Poppins, sans-serif"}
                lineHeight={1}
                mb={1}
              >
                Fullname
              </FormLabel>
              <Input
                ref={fullnameRef}
                w={"100%"}
                color={"gray.600"}
                fontSize={"14px"}
                type={"text"}
                name={"fullname"}
                value={formData.fullname}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={"Enter your fullname"}
                _placeholder={{ fontSize: "14px", color: "gray.600" }}
                borderRadius={"6px"}
                bg={"background"}
                borderWidth={1}
                borderColor={"gray.200"}
                _hover={{ borderColor: "gray.300" }}
                _focusVisible={{ borderColor: "blue.300" }}
              />
            </FormControl>

            <FormControl id={"email"} isRequired w={"100%"}>
              <FormLabel
                htmlFor={"email"}
                color={"secondary.700"}
                fontSize={"12px"}
                fontWeight={"500"}
                fontFamily={"Poppins, sans-serif"}
                lineHeight={1}
                mb={1}
              >
                Email Address
              </FormLabel>
              <Input
                ref={emailRef}
                w={"100%"}
                color={"gray.600"}
                fontSize={"14px"}
                type={"email"}
                name={"email"}
                value={formData.email}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter your email address"
                _placeholder={{ fontSize: "14px", color: "gray.600" }}
                borderRadius={"6px"}
                bg={"background"}
                borderWidth={1}
                borderColor={"gray.200"}
                _hover={{ borderColor: "gray.300" }}
                _focusVisible={{ borderColor: "blue.300" }}
              />
            </FormControl>

            <FormControl id="password" isRequired w={"100%"}>
              <FormLabel
                htmlFor="password"
                color={"secondary.700"}
                fontSize={"12px"}
                fontWeight={"500"}
                fontFamily={"Poppins, sans-serif"}
                lineHeight={1}
                mb={1}
              >
                Password
              </FormLabel>
              <InputGroup>
                <Input
                  ref={passwordRef}
                  w={"100%"}
                  color={"gray.600"}
                  fontSize={"14px"}
                  type={showPassword ? "text" : "password"}
                  name={"password"}
                  value={formData.password}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter your password"
                  _placeholder={{ fontSize: "14px", color: "gray.600" }}
                  borderRadius={"6px"}
                  bg={"background"}
                  borderWidth={1}
                  borderColor={"gray.200"}
                  _hover={{ borderColor: "gray.300" }}
                  _focusVisible={{ borderColor: "blue.300" }}
                />
                <InputRightElement>
                  <Icon
                    boxSize={"14px"}
                    as={showPassword ? ViewOffIcon : ViewIcon}
                    color={"secondary.300"}
                    cursor={"pointer"}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            {!!errorMsg && (
              <Text
                w={"100%"}
                fontSize={"sm"}
                color={"red.500"}
                textAlign={"center"}
                borderRadius={"6px"}
                bg={"rgba(255, 0, 0, .02)"}
                borderStyle={"dashed"}
                borderWidth={1}
                borderColor={"rgba(255, 0, 0, .35)"}
                p={3}
                mt={2}
              >
                {errorMsg}
              </Text>
            )}
          </Flex>

          <Flex direction={"column"} align={"center"} gap={4}>
            <Button
              mt={4}
              py={"10px"}
              w={"100%"}
              h={"fit-content"}
              size={"sm"}
              type={"submit"}
              bg={"primary.400"}
              color={"primary.100"}
              onClick={handleSubmit}
              isLoading={submitting}
              isDisabled={redirecting}
              loadingText={"Processing"}
              _hover={{ bg: "primary.500" }}
            >
              Register
            </Button>

            <Flex
              direction={"column"}
              align={"center"}
              textAlign={"center"}
              gap={1}
            >
              <Text fontSize={"14px"} color={"gray.600"}>
                Or Register with:
              </Text>

              <Flex
                justify={"center"}
                align={"center"}
                p={3}
                boxShadow={
                  "rgba(0,193,180, .4) 0px 2px 5px -1px , rgba(0,193,180, .3) 0px 1px 3px -1px"
                }
                borderRadius={"10px"}
                pointerEvents={redirecting || submitting ? "none" : "auto"}
                cursor={redirecting || submitting ? "not-allowed" : "pointer"}
                transition={"all ease 0.3s"}
                _hover={{ bg: hexToRgba("#00C1B4", 0.2) }}
                onClick={() => thirdPartySignIn()}
              >
                {redirecting ? (
                  <Spinner w={"30px"} h={"30px"} />
                ) : (
                  <Image src={googleImg} alt="Google" boxSize={"30px"} />
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex direction={"column"} gap={4} w="100%" maxW="460px">
          <Flex direction="column" gap={4} w="100%">
            <FormControl id="code" isRequired w={"100%"}>
              <FormLabel
                htmlFor={"code"}
                color={"secondary.700"}
                fontSize={"12px"}
                fontWeight={"500"}
                fontFamily={"Poppins, sans-serif"}
                lineHeight={1}
                mb={1}
              >
                Verification Code
              </FormLabel>
              <Input
                ref={fullnameRef}
                w={"100%"}
                color={"gray.600"}
                fontSize={"14px"}
                type={"text"}
                name={"code"}
                value={code}
                onChange={handleCodeChange}
                onKeyDown={handleCodeKeyDown}
                placeholder="Enter the verification code"
                _placeholder={{ fontSize: "14px", color: "gray.600" }}
                borderRadius={"6px"}
                bg={"background"}
                borderWidth={1}
                borderColor={"gray.200"}
                _hover={{ borderColor: "gray.300" }}
                _focusVisible={{ borderColor: "blue.300" }}
              />
              <FormHelperText>
                The verification code has been sent to the email address
                provided.
              </FormHelperText>
            </FormControl>

            {!!errorMsg && (
              <Text
                w={"100%"}
                fontSize={"sm"}
                color={"red.500"}
                textAlign={"center"}
                borderRadius={"6px"}
                bg={"rgba(255, 0, 0, .02)"}
                borderStyle={"dashed"}
                borderWidth={1}
                borderColor={"rgba(255, 0, 0, .35)"}
                p={3}
                mt={2}
              >
                {errorMsg}
              </Text>
            )}
          </Flex>

          <Button
            mt={4}
            py={"10px"}
            w={"100%"}
            h={"fit-content"}
            size={"sm"}
            type={"submit"}
            bg={"primary.400"}
            color={"primary.100"}
            onClick={handleVerify}
            isLoading={isVerifying || isSigningIn}
            loadingText={isVerifying ? "Verifying" : "Signing In"}
            _hover={{ bg: "primary.500" }}
          >
            Verify
          </Button>
        </Flex>
      )}
    </>
  );
}
