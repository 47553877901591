import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { titleCase } from "utils/helpers";
import { IoIosFlash } from "react-icons/io";

interface RateLimitModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function RateLimitModal({ isOpen, onClose }: RateLimitModalProps) {
  const { user } = useSelector(selectCurrentAuthData);
  // Hooks
  const navigate = useNavigate();

  // Responsiveness
  const fromLeft = useBreakpointValue({ base: "5%", lg: "33%" });
  const maxW = useBreakpointValue({ base: "100%", lg: "760px", xl: "760px" });
  const width = useBreakpointValue({
    base: "100%",
    lg: "fit-content",
    xl: "760px",
  });

  const state = window.localStorage.getItem("initialRateLimitSeen");
  const initialRateLimitSeen = state ? JSON.parse(state) : false;

  // Refactor if RATE_LIMIT for premium or Teams is no longer unlimited
  const RATE_LIMIT = user?.user_type === "free" ? 5 : 100;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg={"transparent"} />
      <ModalContent
        p={1}
        top="2%"
        w={width}
        h={{ base: "82px", lg: "82px", xl: "70px" }}
        // h={"82px"}
        maxW={maxW}
        left={fromLeft}
        bg={"background"}
        position="absolute"
        borderRadius={"12px"}
        transform="translateX(-50%)"
      >
        <ModalCloseButton
          position="absolute"
          top="50%"
          right="8px"
          transform="translateY(-50%)"
        />
        <ModalBody mb={4}>
          <Flex justify="space-between" align="center" mb={2}>
            <Flex direction="column" gap={2}>
              <Heading as="h4" size="sm" fontWeight={700}>
                {!initialRateLimitSeen ? (
                  <>Your have a rate limit of {RATE_LIMIT} queries.</>
                ) : (
                  <>{titleCase(user?.user_type)} plan rate limit exceeded.</>
                )}
              </Heading>

              <Text fontFamily={"Poppins, sans-serif"} fontSize={"12px"}>
                {!initialRateLimitSeen ? (
                  <>
                    {/* The limit resets in 6 hours, if exceeded. Upgrade your
                    plan to increase the limit. */}
                    Exceeding {RATE_LIMIT} queries requires a 6-hour wait for a
                    reset. Upgrade your plan to increase the limit.
                  </>
                ) : (
                  <>
                    Limit will reset in 6 hours. Upgrade your plan to increase
                    the limit.
                  </>
                )}
              </Text>
            </Flex>

            <Flex align="center" gap={1} alignItems="flex-end" mr={4}>
              <Button
                type={"submit"}
                borderRadius={"30px"}
                bg={"highlight.primary"}
                color={"background"}
                h={"fit-content"}
                w={"fit-content"}
                py={2}
                px={4}
                fontSize={"14px"}
                fontWeight={"500"}
                letterSpacing={".02rem"}
                leftIcon={<IoIosFlash />}
                _active={{ bg: "highlight.primary" }}
                _hover={{ bg: "highlight.primary" }}
                _focus={{ bg: "highlight.primary" }}
                _focusWithin={{ bg: "highlight.primary" }}
                onClick={() => navigate("/pricing")}
              >
                Upgrade
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RateLimitModal;
