import { useSelector } from "react-redux";
import { selectCurrentPostsData } from "redux/features/posts/postsSlice";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  Text,
  useToast,
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  Badge,
  useTheme,
  Spinner,
} from "@chakra-ui/react";

import { errorHandler } from "utils/helpers";
import { useTeamsAPI } from "api/useTeamsAPI";
import { InviteProps } from "models/posts/PostProps";

import { DeleteIcon } from "@chakra-ui/icons";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import { useState } from "react";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useUsersAPI } from "api/useUsersAPI";

interface HeaderProps {
  text: string;
  index: number;
}

interface isOwnerProps {
  isOwner: boolean;
}

const tableStyle = {
  height: "94%",
  width: "100%",
  padding: "0",
};

const invitesHeaders = ["Email", "Permission", "Action"];

export default function PendingInvites({ isOwner }: isOwnerProps) {
  const { user } = useSelector(selectCurrentAuthData);

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";

  // Hooks
  const toast = useToast();
  const queryClient = useQueryClient();

  //States
  const [selected, setSelected] = useState<string | null>(null);

  // Theme
  const { colors } = useTheme();

  const { teamData } = useSelector(selectCurrentPostsData);
  const { deleteTeamInvite } = useTeamsAPI();
  const { saveUserActivity } = useUsersAPI();

  const pendingInvites =
    teamData?.invites?.filter((invite) => invite.accepted === false) || [];

  const deleteInviteMutation = useMutation({
    mutationFn: deleteTeamInvite,
    onSuccess: () => {
      toast({
        position: "top-right",
        duration: 3000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Invitation deleted successfully
          </Box>
        ),
      });

      queryClient.invalidateQueries({ queryKey: ["team"] });
      setSelected(null);
    },
    onError: (error) => {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
      setSelected(null);
    },
  });

  const removeTeamInvite = async (email: string) => {
    setSelected(email);
    // Save User activity Logs
    const activity = {
      user_id: user.id,
      activity: `Deleted member invite: ${email}`,
    };
    await saveUserActivity(activity);
    deleteInviteMutation.mutate(email);
  };

  const renderRow = (data: InviteProps, index: number) => {
    return (
      <Tr
        key={"table-row" + index}
        py={5}
        transition="background 0.3s ease"
        _hover={{ cursor: "pointer", bg: "gray.100" }}
      >
        <Td
          key={"email" + data.email + index}
          borderColor={"gray.100"}
          p={1.5}
          isTruncated
        >
          {data?.email}
        </Td>
        <Td
          key={"permission" + data.permission + index}
          borderColor={"gray.100"}
          p={1.5}
          isTruncated
        >
          <Badge variant="outline" color={isLight ? "primary" : "white"}>
            {data?.permission}
          </Badge>
        </Td>
        <Td borderColor={"gray.100"}>
          {selected === data.email ? (
            <Spinner size="sm" color="red.500" />
          ) : (
            <IconButton
              isRound={true}
              variant="solid"
              colorScheme="red"
              aria-label="Done"
              size={"sm"}
              onClick={() => removeTeamInvite(data.email)}
              icon={<DeleteIcon />}
              isDisabled={false}
            />
          )}
        </Td>
      </Tr>
    );
  };

  return (
    <>
      {pendingInvites?.length > 0 && isOwner ? (
        <Box mt={12}>
          <Text color={colors.highlight.primary}>Pending Invites</Text>
          <CustomScrollBar style={tableStyle} mt={8}>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  {invitesHeaders.map((text, index) => (
                    <Header
                      key={"table-header" + index}
                      text={text}
                      index={index}
                    />
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {pendingInvites?.map((row: any, index: number) =>
                  renderRow(row, index)
                )}
              </Tbody>
            </Table>
          </CustomScrollBar>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

function Header({ text, index }: HeaderProps) {
  return (
    <Th
      key={"table-header" + index}
      py={2}
      px={2}
      textAlign={"left"}
      position="sticky"
      top={0}
      zIndex={1}
      bg="gray.100"
      borderLeftRadius={index === 0 ? "md" : "none"}
      borderRightRadius={index === invitesHeaders.length - 1 ? "md" : "none"}
      borderRight={index !== invitesHeaders.length - 1 ? "1px solid" : "none"}
      borderRightColor={"gray.200"}
    >
      {text}
    </Th>
  );
}
