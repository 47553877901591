export const TYPES = {
  FREE: "free",
  BASIC: "basic",
  PREMIUM: "premium",
  TEAMS: "teams",
};

export const PERMISSIONS = {
  TEAMS_INVITEE: "teams_invitee",
};
